import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';
import openModal from '@/util/modalSetter';
import parkingRoutes from './modules/parking';
import periodRoutes from './modules/period';
import webDiscountRoutes from './modules/webDiscount';
import prepaymentRoutes from './modules/prepayment';
import unpaidRoutes from './modules/unpaid';
import boardRoutes from './modules/board';
import mypageRoutes from './modules/mypage';
import utilRoutes from './modules/util';

import ErrorPage from '@/pages/subpage/util/errorPage.vue';

const routes = [
  {
    path: '/',
    redirect: '/main',
  },
  {
    path: '/main',
    name: 'main',
    component: () =>
      import(
        /* webpackChunkName: "view-main-vue" */ '@/pages/mainpage/mainPage'
      ),
    meta: { category: ['메인', '메인'] },
  },
  ...parkingRoutes,
  ...prepaymentRoutes,
  ...unpaidRoutes,
  ...periodRoutes,
  ...webDiscountRoutes,
  ...boardRoutes,
  ...mypageRoutes,
  ...utilRoutes,
  // Not Found Page
  {
    path: '/404',
    name: 'notFound',
    component: ErrorPage,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // 페이지 새로고침 시 스크롤을 최상단으로 이동
    return { top: 0 };
  },
});

// 최상단 스크롤 함수
function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 };
  }
}

router.beforeEach((to, from, next) => {
  const isLogin = store.state.isLogin;
  const empDiv = store.state.empDiv;
  const { empcategory } = to.meta;

  if (to.matched.some(record => record.meta.authorization)) {
    if (!isLogin) {
      next('/loginmain');
      return;
    }
  }

  if (to.matched.some(record => record.meta.empcategory)) {
    if (!empcategory.includes(empDiv)) {
      if (empDiv === 'USR') {
        openModal('해당서비스는 사업자회원 전용 서비스입니다.', 'warning');
        next('/main');
        return;
      }

      if (empDiv === 'BSN') {
        openModal('해당서비스는 일반회원 전용 서비스입니다.', 'warning');
        next('/main');
        return;
      }

      openModal('로그인 후 이용가능한 서비스입니다.', 'warning');
      next('/loginmain');
      return;
    }
  }

  if (to.matched.some(record => record.meta.onlyGuest)) {
    if (isLogin) {
      return next({ path: '/main' });
    }
  }

  next();
});

// 라우터 이동시 스크롤 위치를 최상단으로 이동
router.afterEach((to, from) => {
  window.scrollTo(scrollBehavior(to, from, null));
});

export default router;
