<template>
  <div v-if="$route.name == 'main'">
    <GnbArea></GnbArea>
    <RouterView></RouterView>
    <ScrollTop></ScrollTop>
    <FooterArea></FooterArea>
  </div>
  <div v-else-if="$route.meta.category">
    <GnbArea></GnbArea>
    <section id="container">
      <TopNavigation></TopNavigation>
      <div id="subPage">
        <RouterView></RouterView>
      </div>
      <ScrollTop></ScrollTop>
    </section>
    <FooterArea></FooterArea>
  </div>
  <div v-else-if="$route.meta.testPage">
    <RouterView></RouterView>
  </div>
  <div v-else>
    <GnbArea></GnbArea>
    <div id="subPage">
      <RouterView></RouterView>
    </div>
    <FooterArea></FooterArea>
  </div>
  <CheckModal v-if="alertType === 'check'" />
  <WarningModal v-else-if="alertType === 'warning'" />
  <ConfirmModal v-else-if="alertType === 'confirm'" />
</template>

<script>
import GnbArea from '@/pages/layout/GnbArea.vue';
import FooterArea from '@/pages/layout/FooterArea.vue';
import TopNavigation from '@/components/TopNavigation.vue';
import ScrollTop from '@/components/ScrollTop.vue';
import { isArray, isEmpty } from 'lodash';
import CheckModal from '@/components/modal/CheckModal.vue';
import WarningModal from '@/components/modal/WarningModal.vue';
import ConfirmModal from '@/components/modal/ConfirmModal.vue';

export default {
  components: {
    GnbArea,
    FooterArea,
    TopNavigation,
    ScrollTop,
    CheckModal,
    WarningModal,
    ConfirmModal,
  },

  computed: {
    alertType() {
      return this.$store.state.alertType;
    },
  },

  watch: {
    async $route(to, from) {
      const isQueryStringEmpty = isEmpty(to.query);

      if (from.path === '/' && isQueryStringEmpty) {
        this.setRefreshed(false);
      } else if (from.path === to.path && isQueryStringEmpty) {
        const childComponents = to.matched;

        if (isArray(childComponents)) {
          await this.$nextTick();
          const instanceComponent = childComponents[0].instances?.default;

          if (instanceComponent?.init) {
            this.setRefreshed(true);
            this.$pushQueryString(null, null);
            instanceComponent.init();
          }
        }
      }
    },
  },

  beforeCreate() {
    window.addEventListener('beforeunload', () => this.setRefreshed(true));
  },

  methods: {
    setRefreshed(status) {
      this.$store.commit('isRefreshed', status);
    },
  },
};
</script>
