function LoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/subpage/board/${view}.vue`
    );
}

const boardRoutes = [
  /* 알림마당 */

  // 공지사항
  {
    path: '/notice/list',
    name: 'noticeList',
    component: LoadView('noticeList'),
    meta: {
      category: ['알림마당', '공지사항'],
    },
  },
  // 공지사항 - 상세
  {
    path: '/notice/view',
    name: 'noticeView',
    component: LoadView('noticeView'),
    meta: {
      category: ['알림마당', '공지사항'],
    },
  },

  // 자주 묻는 질문
  {
    path: '/faq/view',
    name: 'faqView',
    component: LoadView('faqView'),
    meta: {
      category: ['알림마당', '자주 묻는 질문'],
    },
  },

  // 묻고 답하기
  // {
  //   path: '/customer',
  //   name: 'customer',
  //   component: () => {
  //     const link = '/';
  //     if (/Mobi/i.test(window.navigator.userAgent)) {
  //       location.href = link;
  //     } else {
  //       window.open(link, '_blank');
  //     }
  //   },
  //   meta: {
  //     category: ['알림마당', '묻고 답하기'],
  //   },
  // },
];

export default boardRoutes;
