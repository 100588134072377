import axios from 'axios';
import router from '@/routers/index.js';
import store from '@/store/index';
import openModal from '@/util/modalSetter';

const axiosHook = async (
  method,
  url,
  params,
  config,
  isAlert,
  timeOut,
  retryCount = 3,
  isRetry,
  isFinalRetry,
) => {
  const timeout = timeOut || process.env.VUE_APP_HTTP_REQUEST_TIME_OUT;

  let response = null;

  const instance = axios.create({
    timeout: timeout,
  });

  try {
    switch (method) {
      case 'put':
        response = await instance.put(url, params, config);
        break;
      case 'patch':
        response = await instance.patch(url, params, config);
        break;
      case 'post':
        response = await instance.post(url, params, config);
        break;
      case 'get':
        response = await instance.get(url, {
          params,
          ...config,
        });
        break;
      case 'delete':
        response = await instance.delete(url, {
          data: { ...params },
          ...config,
        });
        break;
      default:
        break;
    }
  } catch (error) {
    if (isRetry) {
      if (isFinalRetry && !isAlert) {
        openModal('잠시 후 다시 시도해주세요.', 'warning');
      } else if (isFinalRetry) {
        return 'finalIsEnd';
      }
      return;
    }

    const errorRes = error?.response;

    if (error.code === 'ECONNABORTED') {
      for (let i = 1; i <= retryCount; i++) {
        const isLastRetry = i === retryCount;
        response = await axiosHook(
          method,
          url,
          params,
          config,
          isAlert,
          timeOut,
          retryCount,
          true,
          isLastRetry,
        );
        if (response) {
          if (isSessionExpired(response)) {
            routerMove('/main');
          }
          return response;
        }
      }
    } else if (errorRes) {
      if (errorRes.status === 413) {
        openModal('파일 용량이 초과했습니다.', 'warning');
        return;
      }

      const errorCodeStart = Math.trunc(errorRes.status / 100);
      if (errorCodeStart === 4) {
        openModal('잘못된 요청입니다.', 'warning');
      } else if (errorCodeStart === 5) {
        openModal(
          '서버 오류가 발생했습니다.\n관리자에게 문의해주세요.',
          'warning',
        );
      }
    }
    return;
  }

  if (!isSessionExpired(response)) {
    routerMove('/main');
  }

  return response?.data ?? {};
};

const isSessionExpired = response => {
  if (!(response?.data?.sessionExpired && !store.state.isSessionExpired))
    return true;

  openModal('로그인 시간이 만료되었습니다.\n다시 로그인 해주세요.', 'warning');
  store.commit('isSessionExpired', true);
  store.dispatch('logout');
  return false;
};

const routerMove = route => {
  router.push(route).catch(err => {
    if (
      err.name !== 'NavigationDuplicated' &&
      !err.message.includes('Avoided redundant navigation to current location')
    ) {
      return;
    }
  });
};

export default axiosHook;
