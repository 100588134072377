function LoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/subpage/unpaid/${view}.vue`
    );
}

const unpaidRoutes = [
  // 미납요금 - 안내
  {
    path: '/unpaid/info',
    name: 'unpaidInfo',
    component: LoadView('unpaidInfo'),
    meta: {
      category: ['주차요금 결제', '미납요금 납부안내'],
    },
  },
  // 미납주차요금 - 조회
  {
    path: '/unpaid/search',
    name: 'unpaidSearch',
    component: LoadView('unpaidSearch'),
    meta: {
      category: ['주차요금 결제', '미납요금 조회/결제'],
    },
  },
  // 미납주차요금 - 상세/결제
  {
    path: '/unpaid/view',
    name: 'unpaidView',
    component: LoadView('unpaidView'),
    meta: {
      category: ['주차요금 결제', '미납요금 조회/결제'],
    },
  },
];

export default unpaidRoutes;
