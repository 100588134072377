<template>
  <a class="scroll__button" @click="scrollTop">
    <i class="icon"></i><span>TOP</span>
  </a>
</template>

<script>
import '@/assets/sass/base/_font.scss';

export default {
  data() {
    return {};
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll__button {
  position: fixed;
  display: block !important;
  right: 60px;
  bottom: 0;
  z-index: 20;
  transition: 0.2s;
  width: 50px;
  height: 50px;
  background-image: linear-gradient(to bottom, #a52131, #d65d6c);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;

  .icon {
    display: block;
    position: absolute;
    top: 13px;
    left: 50%;
    transform: translateX(-50%);
    width: 8px;
    height: 14px;
    background-image: url(../assets/images/ico/ico-scroll-top.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  span {
    position: absolute;
    bottom: 9px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    font-family: 'Pretendard';
    color: #ffffff;
    text-align: center;
    transition: 0.2s;
  }
}

@media screen and (max-width: 768px) {
  .scroll__button {
    right: 12px;
  }
}
</style>
