import axiosHook from '../axios';
import axiosAll from '../axiosAll';

export default {
  // 로그인
  login(params) {
    return axiosHook('post', '/api/actionLogin.json', params);
  },

  // 로그아웃
  logout() {
    return axiosHook('post', '/api/actionLogout.json');
  },

  // 아이디 중복체크
  duplicateId(empNo) {
    return axiosHook('get', '/api/member/find-duplicate-id', {
      checkId: empNo,
    });
  },

  // 일반 회원가입
  signupGeneral(params) {
    return axiosHook('post', '', params);
  },

  // 사업자 회원가입
  signupBusiness(params) {
    return axiosHook('post', '/api/member/bsn', params);
  },

  // 사업자번호 확인
  checkBusinessLicense(companyNo, headers) {
    return axiosHook(
      'post',
      'https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=dABGu3kpG9upRylbgp7ti3Cn08k9lhk%2FBRT%2Fg%2BnLgrDXwL09YEHYrqr%2FL8rdkGV%2FK6V4jCqT2oMrUBgKaeqxgA%3D%3D',
      { b_no: [companyNo] },
      headers,
    );
  },

  // 사업자 아이디 찾기
  findBsnId(params) {
    return axiosHook('get', `/api/member/bsn/findId`, params);
  },

  // 사업자 비밀번호 변경
  changeBsnPassword(params) {
    return axiosHook('patch', `/api/member/bsn/changePw`, params);
  },

  findBusinessLicense(companyNo) {
    return axiosHook('get', '/api/member/bsn/findCompanyNumber', {
      companyNo: companyNo,
    });
  },

  /* 통합검색 */
  // 통합 조회 -> 메뉴, 공지사항, 자주 묻는 질문
  async getUnifiedSearch(keyword) {
    const urls = [
      `/api/sitemap?searchKeyword=${keyword}`,
      `/api/board/notice/list?searchCondition=TITLE&searchKeyword=${keyword}`,
      `/api/board/faq/list?searchKeyword=${keyword}`,
    ];

    const response = await axiosAll(urls);

    const [menuObj, noticeObj, faqObj] = response;

    const item = {
      menuObj,
      noticeObj,
      faqObj,
    };

    return item;
  },
};
