<template>
  <article>
    <!--바로가기 navi-->
    <div id="accNav">
      <router-link to="#content">본문 바로가기</router-link>
      <router-link to="#footer">푸터 바로가기</router-link>
    </div>
    <!-- Web -->
    <header id="web">
      <div class="header-wrap">
        <div class="inner">
          <div class="header">
            <h1 class="header-main-logo">
              <router-link to="/main">
                <img
                  :src="require('@/assets/images/common/header-main-logo.png')"
                  alt="통합주차포털"
                />
              </router-link>
            </h1>
            <div class="searchBar">
              <input
                type="text"
                title="검색창 입력"
                placeholder="검색어를 입력하세요."
                v-model.trim="searchKeyword"
                @keyup.enter="search"
                @focus="focused"
                @blur="focusOut"
              />
              <button type="button" class="searchBtn" @click="search">
                <span class="hidden">검색</span>
              </button>
            </div>

            <div class="utilBtn" v-if="!isLogin">
              <div class="login" @click="$pushQueryString('/loginmain')">
                <img
                  :src="require('@/assets/images/common/login-icon.svg')"
                  alt="로그인"
                />
                <p>로그인</p>
              </div>
              <div class="signup" @click="$pushQueryString('/signupmain')">
                <img
                  :src="require('@/assets/images/common/signup-icon.svg')"
                  alt="회원가입"
                />
                <p>회원가입</p>
              </div>
            </div>
            <div class="utilBtn" v-else>
              <div class="userInfo">
                <p>반갑습니다.</p>
                <p class="name">{{ empNm }}님</p>
              </div>
              <div class="logout" @click="logout">
                <img
                  :src="require('@/assets/images/ico/ico-logout.svg')"
                  alt="로그아웃"
                />
                <p>로그아웃</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gnb-wrap">
        <nav class="gnb">
          <div class="depth_bg" :class="{ mouseover: isMouseover }"></div>
          <ul class="depth01">
            <li
              v-for="(firDepth, index) in firDepthList"
              :key="index"
              @mouseover="openGnbmenu()"
              @mouseleave="closeGnbmenu()"
            >
              <router-link :to="firDepth.link">
                {{ firDepth.title }}
              </router-link>
              <div class="depth_wrap" :class="{ mouseover: isMouseover }">
                <div>
                  <ul class="depth02">
                    <li
                      v-for="(secDepth, index) in firDepth.menuList"
                      :key="index"
                    >
                      <router-link :to="secDepth.link">
                        {{ secDepth.title }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>

    <!-- mobile -->
    <header id="mobile">
      <div class="header-wrap">
        <div class="content-inner">
          <div class="header-title">
            <h1 class="header-main-logo">
              <router-link to="/main">
                <img
                  :src="require('@/assets/images/common/header-main-logo.png')"
                  alt="통합주차포털"
                />
              </router-link>
            </h1>
            <div class="header-main-bar">
              <div class="searchBar">
                <button
                  type="button"
                  class="searchButton"
                  v-if="!searchBarShow"
                  @click="showSearchBar"
                >
                  <span class="hidden">검색</span>
                </button>
                <button
                  type="button"
                  class="menuButton"
                  @click="btnmenu = true"
                  v-if="!btnmenu && !searchBarShow"
                >
                  <span class="hidden">메뉴</span>
                </button>
                <button
                  type="button"
                  class="closeMenu"
                  v-if="btnmenu || searchBarShow"
                  @click="closeButton"
                >
                  <span class="hidden">메뉴 닫기</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav class="gnb" v-show="btnmenu">
        <article class="gnb-header">
          <div class="utilBtn" v-if="!isLogin">
            <div class="login" @click="this.$pushQueryString('/loginmain')">
              <img
                :src="require('@/assets/images/ico/ico-login.svg')"
                alt="로그인"
              />
              <p>로그인</p>
            </div>
            <div class="signup" @click="this.$pushQueryString('/signupmain')">
              <img
                :src="require('@/assets/images/ico/ico-signup.svg')"
                alt="회원가입"
              />
              <p>회원가입</p>
            </div>
          </div>
          <div class="utilBtn" v-else>
            <div class="userInfo">
              <p class="name">{{ empNm }}님</p>
            </div>
            <div class="logout" @click="logout">
              <img
                :src="require('@/assets/images/ico/ico-logout.svg')"
                alt="로그아웃"
              />
              <p>로그아웃</p>
            </div>
          </div>
        </article>
        <article class="gnb-body">
          <div class="leftArea">
            <ul class="1depth">
              <li
                v-for="(firDepth, index) in firDepthList"
                :key="index"
                @click="showTwoDepth(firDepth)"
                :class="{ active: firDepth.selectMenu }"
              >
                {{ firDepth.title }}
              </li>
            </ul>
          </div>
          <div class="rightArea">
            <ul class="2depth">
              <li
                v-for="(secDepth, index) in secDepthList"
                :key="index"
                @click="$pushQueryString(secDepth.link)"
              >
                {{ secDepth.title }}
              </li>
            </ul>
          </div>
        </article>
      </nav>
      <nav class="gnb" v-show="searchBarShow">
        <article class="gnb-header">
          <div class="searchBar">
            <input
              type="text"
              title="검색창 입력"
              placeholder="검색어를 입력하세요."
              v-model="searchKeyword"
              @keyup.enter="search"
              @focus="focused"
              @blur="focusOut"
            />
            <button type="button" class="searchBtn" @click="search">
              <span class="hidden">검색</span>
            </button>
          </div>
        </article>
        <article class="gnb-body searchBar"></article>
      </nav>
    </header>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import api from '@/api/modules/util';
import openModal from '@/util/modalSetter';

export default {
  data() {
    return {
      firDepthList: [],
      secDepthList: [],
      selectMenu: false,
      siteMap: false,
      searchBarShow: false,
      btnmenu: false,

      isMouseover: false,

      searchKeyword: '',
    };
  },

  computed: {
    ...mapGetters({
      menuList: 'getMenuList',
    }),

    isLogin() {
      return this.$store.state.isLogin;
    },

    empNm() {
      return this.$store.state.empNm;
    },

    empDiv() {
      return this.$store.state.empDiv;
    },
  },

  watch: {
    isLogin() {
      this.getGnbMenuList();
    },

    $route() {
      this.btnmenu = false;
      this.siteMap = false;
    },
  },

  created() {
    this.getGnbMenuList();
  },

  methods: {
    getGnbMenuList() {
      const copy = cloneDeep(this.menuList);

      const recursion = (menu = []) => {
        for (let i = 0; i < menu.length; i++) {
          if (!this.isLogin) {
            if (menu[i].link.includes('mypage')) {
              menu[i] = '';
            }
          } else {
            if (menu[i].auth !== this.empDiv && menu[i].auth !== undefined) {
              menu[i] = '';
            }
          }
          recursion(menu[i].menuList);
        }
      };
      recursion(copy);

      this.firDepthList = copy.filter(v => {
        if (v.menuList) {
          v.menuList = v.menuList.filter(i => i != '');
        }
        return v;
      });
    },

    // gnbMenu Open
    openGnbmenu() {
      this.isMouseover = true;
    },

    // gnbMenu Close
    closeGnbmenu() {
      this.isMouseover = false;
    },

    closeButton() {
      this.btnmenu = false;
      this.searchBarShow = false;
    },

    showSearchBar() {
      this.searchBarShow = true;
      this.btnmenu = false;
    },

    searchBarClose() {
      this.searchBarShow = false;
      this.searchKeyword = '';
    },

    search() {
      if (!this.searchKeyword) return;
      this.$pushQueryString('/searchlist', { q: this.searchKeyword });
      this.searchBarClose();
    },

    showTwoDepth(firDepth) {
      this.firDepthList.forEach(item => (item.selectMenu = false));
      firDepth.selectMenu = true;
      this.secDepthList = firDepth.menuList;
    },

    async logout() {
      const data = await api.logout();

      if (data) {
        this.$store.dispatch('logout', null);
        this.$pushQueryString('/main');
      } else {
        openModal(
          '시스템 오류가 발생하였습니다.\n관리자에게 문의해주세요.',
          'warning',
        );
      }
    },
  },
};
</script>
