import dayjs from 'dayjs';

const ptDateFormat = x => {
  if (x) {
    let js_date = new Date(x);

    let year = js_date.getFullYear();
    let month = (js_date.getMonth() + 1).toString().padStart(2, '0');
    let day = js_date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  } else {
    return '';
  }
};

const dateTimeFormat = (date, format) => {
  if (format === 'YYYY-MM-DD hh:mm:ss') {
    return dayjs(date).format('YYYY-MM-DD hh:mm:ss');
  } else if (format === 'YYYY.MM.DD hh:mm:ss') {
    dayjs(date).format('YYYY.MM.DD hh:mm:ss');
  } else if (format === 'YYYY/MM/DD hh:mm:ss') {
    dayjs(date).format('YYYY/MM/DD hh:mm:ss');
  } else if (format === 'YYYY-MM-DD') {
    dayjs(date).format('YYYY-MM-DD');
  } else if (format === 'YYYY.MM.DD') {
    dayjs(date).format('YYYY.MM.DD');
  } else if (format === 'YYYY/MM/DD') {
    dayjs(date).format('YYYY/MM/DD');
  } else if (format === 'year') {
    dayjs(date).year();
  } else if (format === 'month') {
    dayjs(date).month();
  } else if (format === 'day') {
    dayjs(date).day();
  } else if (format === 'hour') {
    dayjs(date).hour();
  } else if (format === 'minute') {
    dayjs(date).minute();
  } else {
    return dayjs(date);
  }
};

const bytes = (data, to) => {
  const const_term = 1024;

  if (to === 'KB') {
    return (data / const_term).toFixed(3) + 'KB';
  } else if (to === 'MB') {
    return (data / const_term ** 2).toFixed(3) + 'MB';
  } else if (to === 'GB') {
    return (data / const_term ** 3).toFixed(3) + 'GB';
  } else if (to === 'TB') {
    return (data / const_term ** 4).toFixed(3) + 'TB';
  } else {
    return 'Please pass valid option';
  }
};

const comma = x => {
  if (x != null) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

const phoneNumber = phoneNumber => {
  if (!phoneNumber) {
    return phoneNumber;
  }

  phoneNumber = phoneNumber.replace(/[^0-9]/g, '');

  let tmp = '';
  if (phoneNumber.length < 4) {
    return phoneNumber;
  } else if (phoneNumber.length < 7) {
    tmp += phoneNumber.substr(0, 3);
    tmp += '-';
    tmp += phoneNumber.substr(3);
    return tmp;
  } else if (phoneNumber.length == 8) {
    tmp += phoneNumber.substr(0, 4);
    tmp += '-';
    tmp += phoneNumber.substr(4);
    return tmp;
  } else if (phoneNumber.length < 10) {
    if (phoneNumber.substr(0, 2) == '02') {
      //02-123-5678
      tmp += phoneNumber.substr(0, 2);
      tmp += '-';
      tmp += phoneNumber.substr(2, 3);
      tmp += '-';
      tmp += phoneNumber.substr(5);
      return tmp;
    }
  } else if (phoneNumber.length < 11) {
    if (phoneNumber.substr(0, 2) == '02') {
      //02-1234-5678
      tmp += phoneNumber.substr(0, 2);
      tmp += '-';
      tmp += phoneNumber.substr(2, 4);
      tmp += '-';
      tmp += phoneNumber.substr(6);
      return tmp;
    } else {
      //010-123-4567
      tmp += phoneNumber.substr(0, 3);
      tmp += '-';
      tmp += phoneNumber.substr(3, 3);
      tmp += '-';
      tmp += phoneNumber.substr(6);
      return tmp;
    }
  } else {
    //010-1234-5678
    tmp += phoneNumber.substr(0, 3);
    tmp += '-';
    tmp += phoneNumber.substr(3, 4);
    tmp += '-';
    tmp += phoneNumber.substr(7);
    return tmp;
  }
};

const onlyNumber = v => {
  return v.replace(/[^\d.]/g, '').replace(/(\.*)\./g, '$1');
};

const companyNo = companyNo => {
  const regex = /^(\d{3})(\d{2})(\d{5})$/;
  return companyNo.replace(/[^0-9]/g, '').replace(regex, '$1-$2-$3');
};

export default {
  ptDateFormat,
  dateTimeFormat,
  bytes,
  comma,
  phoneNumber,
  onlyNumber,
  companyNo,
};
