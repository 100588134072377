<template>
  <div id="breadCrumb">
    <p class="pageTxt">{{ title }}</p>
  </div>
</template>

<script>
export default {
  computed: {
    title() {
      return this.$route.meta.category[0];
    },
  },
};
</script>
