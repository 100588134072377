import { ref, nextTick } from 'vue';
import store from '@/store/index';
import router from '@/routers';

export const useModal = () => {
  const msg = ref(store.state.msg);
  const alertType = ref(store.state.alertType);
  const url = ref(store.state.url);
  const confirmCancelMessage = ref(store.state?.confirmCancelMessage ?? '취소');
  const confirmOkMessage = ref(store.state?.confirmOkMessage ?? '확인');

  const initModal = () => {
    if (alertType.value !== 'confirm' && store.state.confirmOkHandler) {
      store.state.confirmOkHandler();
    }

    if (url.value && router.currentRoute.value.path !== url.value) {
      router.push(url.value);
    }

    store.dispatch('modalObject', null);
  };

  const focusButton = async modalButton => {
    await nextTick();

    modalButton.focus();
  };

  return {
    msg,
    confirmCancelMessage,
    confirmOkMessage,
    initModal,
    focusButton,
  };
};
