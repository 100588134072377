/* eslint-disable */
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import menuJson from './menuJson.json';

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],

  state: {
    menuList: menuJson.menuList,
    isLogin: false,
    empEsntlId: null,
    empNo: null,
    empNm: null,
    empMobile: null,
    empDiv: null,

    isCheckTerms: false,
    isSessionExpired: false,
    isRefreshed: false,

    //modal
    msg: null,
    alertType: null,
    url: null,
    confirmOkMessage: null,
    confirmCancelMessage: null,
    confirmOkHandler: null,
  },

  getters: {
    getMenuList: state => state.menuList,
  },

  mutations: {
    login: function (state, payload) {
      state.isLogin = true;
      state.empEsntlId = payload.empEsntlId;
      state.empNo = payload.empNo;
      state.empDiv = payload.empDiv;
      state.empNm = payload.empNm;
      state.empMobile = payload.empMobile;
    },
    logout: function (state) {
      state.isLogin = false;
      state.isSessionExpired = false;
      state.empEsntlId = null;
      state.empNo = null;
      state.empDiv = null;
      state.empNm = null;
      state.empMobile = null;
      state.menuList = menuJson.menuList;
    },
    isCheckTerms: function (state, payload) {
      state.isCheckTerms = payload;
    },
    isSessionExpired: function (state, payload) {
      state.isSessionExpired = payload;
    },
    isRefreshed: function (state, payload) {
      state.isRefreshed = payload;
    },
    modalObject: function (state, payload) {
      state.msg = payload?.msg ?? null;
      state.alertType = payload?.alertType ?? null;
      state.url = payload?.url ?? null;
      state.confirmOkMessage = payload?.confirmOkMessage ?? null;
      state.confirmCancelMessage = payload?.confirmCancelMessage ?? null;
      if (payload?.confirmOkHandler) {
        state.confirmOkHandler = payload?.confirmOkHandler;
      }
    },
    confirmOkHandler: (state, payload) => {
      state.confirmOkHandler = payload;
    },
  },

  actions: {
    login: function ({ commit }, payload) {
      commit('login', payload);
    },
    logout: function ({ commit }, payload) {
      commit('logout', payload);
    },
    modalObject: function ({ commit }, payload) {
      commit('modalObject', payload);
    },
    confirmOkHandler: function ({ commit, state }) {
      state.confirmOkHandler();
      commit('confirmOkHandler', null);
    },
  },

  modules: {},
});
