function LoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/subpage/prepayment/${view}.vue`
    );
}

const prepaymentRoutes = [
  // 사전결제 - 조회
  {
    path: '/prepayment/search',
    name: 'prepaymentSearch',
    component: LoadView('prepaymentSearch'),
    meta: {
      category: ['주차요금 결제', '주차요금 사전결제'],
    },
  },
  // 사전결제 - 상세
  {
    path: '/prepayment/view',
    name: 'prepaymentView',
    component: LoadView('prepaymentView'),
    meta: {
      category: ['주차요금 결제', '주차요금 사전결제'],
    },
  },
];

export default prepaymentRoutes;
