<template>
  <section id="APP">
    <MainLayout></MainLayout>
  </section>
</template>

<script>
import MainLayout from './pages/layout/MainLayout.vue';

const agent = window.navigator.userAgent.toLowerCase();
const appName = window.navigator.appName.toLowerCase();

if (
  (appName === 'netscape' && agent.indexOf('trident') !== -1) ||
  agent.indexOf('msie') !== -1
) {
  var ieChkMsg =
    '현재 페이지는 인터넷 익스플로러(IE) 브라우저를 지원하지 않습니다.\n' +
    '크롬 혹은 엣지브라우저에서 정상적으로 작동됩니다.\n' +
    '해당 브라우저로 이동하시겠습니까?';

  if (confirm(ieChkMsg)) {
    window.location = 'microsoft-edge:' + window.location.href;
  } else {
    window.history.back();
  }

  window.open(
    'https://support.microsoft.com/office/160fa918-d581-4932-9e4e-1075c4713595',
    '',
    '_blank',
  );
  window.open('https://go.microsoft.com/fwlink/?linkid=2135547', '', '_blank');
}

export default {
  components: {
    MainLayout,
  },
};
</script>
