import router from '@/routers';

// query fetching 후 router 이동
const pushQueryString = (path, params) => {
  router.push({
    path: path ?? router.currentRoute.value.path,
    query: params ?? null,
  });
};

// history state fetching 후 router 이동
const pushHistoryState = (path, params) => {
  router.push({
    path: path ?? router.currentRoute.value.path,
    state: params ?? null,
  });
};

export { pushQueryString, pushHistoryState };
