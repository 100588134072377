<template>
  <article class="error-form">
    <article class="error-box">
      <div class="txt-area">
        <strong>Page Not Found</strong>
        <p>요청하신 페이지를 찾을수가 없습니다.</p>
        <img
          :src="require('@/assets/images/common/img-error.svg')"
          alt="ERROR 이미지"
        />
      </div>
      <div class="btn-area">
        <ColorButton
          color="red"
          :width="350"
          :height="60"
          :fontSize="22"
          fontFamily="PM"
          @click="$pushQueryString('/main')"
        >
          <template #title>메인으로</template>
        </ColorButton>
      </div>
    </article>
  </article>
</template>

<script>
import ColorButton from '@/components/button/ColorButton.vue';

export default {
  components: {
    ColorButton,
  },
};
</script>
