<template>
  <button
    type="button"
    class="button"
    :style="`
      width: ${width}px;
      height: ${height}px;
      font-size: ${fontSize}px;
      font-family: ${fontName};
      border-radius: ${borderRadius}px;
    `"
  >
    <slot name="title">확인</slot>
  </button>
</template>

<script>
import '@/assets/sass/base/_font.scss';

const fontOption = {
  NR: 'Noto Sans',
  NL: 'Noto Sans Light',
  NM: 'Noto Sans Medium',
  NB: 'Noto Sans Bold',
  NS: 'Noto Sans SemiBold',
  PR: 'Pretendard',
  PM: 'Pretendard Medium',
  PS: 'Pretendard SemiBold',
  PB: 'Pretendard Bold',
};

export default {
  props: {
    width: {
      type: Number,
    },
    height: {
      type: Number,
      default: 40,
    },
    fontSize: {
      type: Number,
      default: 16,
    },
    fontFamily: {
      type: String,
      default: 'PR',
    },
    borderRadius: {
      type: Number,
      default: 5,
    },
  },

  computed: {
    fontName() {
      return fontOption[this.fontFamily];
    },
  },
};
</script>

<style lang="scss">
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c9c9c9;
  background: #ffffff;
  color: #0a0a0a;
  min-width: 80px;
  padding: 0 15px;
  transition: 0.1s;
  white-space: nowrap;
  word-break: keep-all;

  + .button {
    margin-left: 8px;
  }
}

input + .button {
  margin-left: 8px;
}

select + .button {
  margin-left: 8px;
}

p + .button {
  margin-left: 8px;
}

.v-input + .button {
  margin-left: 8px;
}

.v3dp__datepicker + .button {
  margin-left: 8px;
}

@media screen and (max-width: 768px) {
  .button {
    min-width: 80px;
  }

  .table {
    .button {
      max-width: 80px;
    }
  }
}
</style>
