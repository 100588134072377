function GeneralLoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/subpage/mypage/general/${view}.vue`
    );
}

function BusinessLoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/subpage/mypage/business/${view}.vue`
    );
}

const mypageRoutes = [
  /* 마이페이지 - 일반 */

  // 1. 일반 마이페이지 - 회원 정보
  {
    path: '/mypage/general',
    name: 'MypageGeneral',
    component: GeneralLoadView('mypageGeneral'),
    meta: {
      category: ['마이페이지', '회원정보'],
      empcategory: ['USR', 'ADMIN'],
    },
  },
  // 2. 일반 마이페이지 - 차량 정보
  {
    path: '/mypage/general/carInfo',
    name: 'mypageGeneralCarInfo',
    component: GeneralLoadView('mypageGeneralCarInfo'),
    meta: {
      category: ['마이페이지', '회원정보'],
      empcategory: ['USR', 'ADMIN'],
    },
  },

  /* 마이페이지 - 사업자 */

  // 1. 사업자 정보 확인
  {
    path: '/mypage/business/check',
    name: 'MypageBusinessCheck',
    component: BusinessLoadView('mypageBusinessCheck'),
    meta: {
      category: ['마이페이지', '사업자 정보 수정'],
      empcategory: ['BSN'],
    },
  },

  // 2. 사업자 정보 수정
  {
    path: '/mypage/business/update',
    name: 'MypageBusinessUpdate',
    component: BusinessLoadView('mypageBusinessUpdate'),
    meta: {
      category: ['마이페이지', '사업자 정보 수정'],
      empcategory: ['BSN'],
    },
  },

  // 3. 사업자 웹 할인 신청
  {
    path: '/mypage/business/webdiscount',
    name: 'mypageBusinessWebdiscount',
    component: BusinessLoadView('mypageBusinessWebdiscount'),
    meta: {
      category: ['마이페이지', '웹 할인 신청'],
      empcategory: ['BSN'],
    },
  },

  // 4. 사업자 웹 할인 내역
  {
    path: '/mypage/business/webdiscount/history',
    name: 'mypageBusinessWebdiscountHistory',
    component: BusinessLoadView('mypageBusinessWebdiscountHistory'),
    meta: {
      category: ['마이페이지', '웹 할인 내역'],
      empcategory: ['BSN'],
    },
  },
];

export default mypageRoutes;
