<template>
  <BaseButton
    :class="color"
    :width="width"
    :height="height"
    :fontSize="fontSize"
    :fontFamily="fontFamily"
    :borderRadius="borderRadius"
  >
    <template #title>
      <slot name="title"></slot>
    </template>
  </BaseButton>
</template>

<script>
import BaseButton from './BaseButton.vue';

export default {
  components: {
    BaseButton,
  },

  props: {
    color: {
      type: String,
      default: 'border',
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    fontSize: {
      type: Number,
    },
    fontFamily: {
      type: String,
    },
    borderRadius: {
      type: Number,
      default: 5,
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  color: #ffffff;
  border: 0;

  &.border {
    border: 1px solid #a8a8a8;
    color: #0a0a0a;
  }

  &.red {
    background: #d7142b;

    &:hover {
      background-color: #a81021;
    }
  }

  &.redBorder {
    background: #ffffff;
    border: 1px solid #d7142b;
    color: #d7142b;

    &:hover {
      background-color: #d7142b;
      color: #ffffff;
    }
  }

  &.blue {
    background: #0070bd;
  }

  &.dkBlue {
    background: #1c236c;

    &:hover {
      background-color: #273198;
    }
  }

  &.lightBlue {
    background: #b5d0ea;
  }

  &.gray77 {
    background: #777777;

    &:hover {
      background-color: #222222;
    }
  }

  &.grayEA {
    background: #eaeaea;
    color: #222222;

    &:hover {
      background-color: #e0e0e0;
    }
  }
}
</style>
