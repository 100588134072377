function LoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/subpage/webDiscount/${view}.vue`
    );
}

const webDiscountRoutes = [
  /* 웹 할인 */
  // 안내
  {
    path: '/webdiscount/info',
    name: 'webDiscountInfo',
    component: LoadView('webDiscountInfo'),
    meta: {
      category: ['웹 할인', '웹 할인 안내'],
    },
  },
  // 웹 할인 충전
  {
    path: '/webdiscount/buypoint',
    name: 'webDiscountBuypoint',
    component: LoadView('webDiscountBuypoint'),
    meta: {
      category: ['웹 할인', '웹 할인 충전'],
      empcategory: ['BSN', 'ADMIN'],
    },
  },
  // 웹 할인 사용
  {
    path: '/webdiscount/usepoint',
    name: 'webDiscountUsepoint',
    component: LoadView('webDiscountUsepoint'),
    meta: {
      category: ['웹 할인', '웹 할인 사용'],
      empcategory: ['BSN', 'ADMIN'],
    },
  },
];

export default webDiscountRoutes;
