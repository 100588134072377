function GeneralLoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/subpage/period/general/${view}.vue`
    );
}

const periodRoutes = [
  /* 정기권 > 일반 정기권 */
  // 안내
  {
    path: '/generalperiod/info',
    name: 'generalPeriodInfo',
    component: GeneralLoadView('generalPeriodInfo'),
    meta: {
      category: ['정기권', '정기권 안내'],
    },
  },
  // 정기권 신청 - 주차장 리스트
  {
    path: '/generalperiod/list',
    name: 'generalPeriodList',
    component: GeneralLoadView('generalPeriodList'),
    meta: {
      category: ['정기권', '정기권 신청'],
    },
  },
  // 정보입력
  {
    path: '/generalperiod/apply',
    name: 'generalPeriodApply',
    component: GeneralLoadView('generalPeriodApply'),
    meta: {
      category: ['정기권', '정기권 신청'],
      empcategory: ['USR'],
    },
  },
  // 이용현황
  {
    path: '/generalperiod/history',
    name: 'generalPeriodHistory',
    component: GeneralLoadView('generalPeriodHistory'),
    meta: {
      category: ['정기권', '정기권 조회/결제'],
      empcategory: ['USR'],
    },
  },
  // 상세
  {
    path: '/generalperiod/view',
    name: 'generalPeriodView',
    component: GeneralLoadView('generalPeriodView'),
    meta: {
      category: ['정기권', '정기권 조회/결제'],
      empcategory: ['USR'],
    },
  },
];

export default periodRoutes;
