function loadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/subpage/util/${view}.vue`
    );
}

function LoginLoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/subpage/util/login/${view}.vue`
    );
}

function kmcLoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/components/kmc/${view}.vue`
    );
}

function signupLoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/subpage/util/signup/${view}.vue`
    );
}

function GeneralSignupLoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/subpage/util/signup/general/${view}.vue`
    );
}

function businessSignupLoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/subpage/util/signup/business/${view}.vue`
    );
}

const utilRoutes = [
  /* Util */

  // 로그인 - 메인
  {
    path: '/loginmain',
    name: 'loginMain',
    component: LoginLoadView('loginMain'),
    meta: {
      onlyGuest: true,
    },
  },

  // 로그인 - 사업자
  {
    path: '/login',
    name: 'loginPage',
    component: LoginLoadView('loginPage'),
    meta: {
      onlyGuest: true,
    },
  },

  /* kmc */
  {
    path: '/kmc-login-check',
    name: 'KmcLoginCheck',
    component: kmcLoadView('KmcLoginCheck'),
  },

  // 회원가입 - 메인
  {
    path: '/signupmain',
    name: 'signupMain',
    component: signupLoadView('signupMain'),
    meta: {
      onlyGuest: true,
    },
  },

  // 일반 회원가입 - 약관
  {
    path: '/signup/general/agreement',
    name: 'signupGeneralAgreement',
    component: GeneralSignupLoadView('signupGeneralAgreement'),
    meta: {
      category: ['회원가입'],
      onlyGuest: true,
    },
  },

  // 사업자 회원가입
  {
    path: '/signup/business/agreement',
    name: 'signupBusinessAgreement',
    component: businessSignupLoadView('signupBusinessAgreement'),
    meta: {
      category: ['회원가입'],
      onlyGuest: true,
    },
  },
  {
    path: '/signup/business/apply',
    name: 'signupBusinessApply',
    component: businessSignupLoadView('signupBusinessApply'),
    meta: {
      category: ['회원가입'],
      onlyGuest: true,
    },
  },
  {
    path: '/signup/business/result',
    name: 'signupBusinessResult',
    component: businessSignupLoadView('signupBusinessResult'),
    meta: {
      category: ['회원가입'],
      onlyGuest: true,
    },
  },

  // 전체검색
  {
    path: '/searchlist',
    name: 'searchlist',
    component: loadView('searchList'),
  },
];

export default utilRoutes;
