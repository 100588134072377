<template>
  <footer id="footer">
    <div class="top">
      <div class="inner">
        <ul class="link">
          <li>
            <button title="이용약관" @click="isMembershipTermsShow = true">
              이용약관
            </button>
          </li>
          <li>
            <button
              title="개인정보처리방침"
              @click="openUrl('https://www.ycs.or.kr/fmcs/144')"
            >
              개인정보처리방침
            </button>
          </li>
        </ul>
        <div class="family-site">
          <label for="familySite" class="hidden">양천시 패밀리 사이트</label>
          <select
            title="패밀리사이트 선택"
            id="familySite"
            @change="goExternalSite"
            @keydown.up="eventStem($event)"
            @keydown.right="eventStem($event)"
            @keydown.down="eventStem($event)"
            @keydown.left="eventStem($event)"
            ref="moveSite"
          >
            <option value="/main" selected>Family Site</option>
            <option value="https://www.yangcheon.go.kr">양천구청</option>
            <option value="https://www.ycs.or.kr">양천구시설관리공단</option>
          </select>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="inner">
        <h1 class="footer-logo">
          <img
            :src="require('@/assets/images/common/footer-logo.png')"
            alt="양주시설공단로고"
          />
        </h1>
        <address class="footer-address">
          <p class="address">
            주차관리처: (08095) 서울특별시 양천구 목동동로 81
            <span class="address down"> 해누리타운 8층(신정동) </span>
          </p>
          <p class="address">
            대표전화: 02-2061-3414(시설관리공단)
            <!-- <span class="address sp">홈페이지 이용문의: 041-536-8883</span> -->
            <span class="address sp">사업자등록번호 : 117-82-02170</span>
          </p>

          <p class="copyright">
            COPYRIGHT ⓒ 2024 양천구시설관리공단 ALL RIGHTS RESERVED
          </p>
        </address>
      </div>
    </div>
  </footer>

  <MembershipTermsModal v-if="isMembershipTermsShow">
    <template #info> 이용약관 </template>
    <template #content>
      <MembershipTerms class="footerTerm" />
    </template>
    <template #button>
      <button class="btn closeBtn" @click="closeModal">
        <span class="hidden">닫기</span>
      </button>
    </template>
  </MembershipTermsModal>
</template>

<script>
import MembershipTermsModal from '@/components/ContentsModal.vue';
import MembershipTerms from '@/components/terms/MembershipTerms.vue';

export default {
  components: {
    MembershipTermsModal,
    MembershipTerms,
  },

  data() {
    return {
      showEmailModal: false,
      isMembershipTermsShow: false,
    };
  },

  methods: {
    openWebsite(url) {
      window.open(url);
    },

    goExternalSite() {
      const agent = navigator.userAgent.toUpperCase();
      let safari =
        agent.indexOf('SAFARI') >= 0 && agent.indexOf('CHROME') < 0
          ? true
          : false;

      if (/Mobi/i.test(window.navigator.userAgent) || safari) {
        location.href = this.$refs.moveSite.value;
      } else {
        window.open(this.$refs.moveSite.value, '_blank');
      }

      this.$refs.moveSite.value = '/main';
    },

    eventStem(e) {
      switch (e.keyCode) {
        case 37:
          e.preventDefault();
          break;
        case 38:
          e.preventDefault();
          break;
        case 39:
          e.preventDefault();
          break;
        case 40:
          e.preventDefault();
          break;
      }
    },

    closeModal() {
      this.isMembershipTermsShow = false;
    },

    openUrl(url) {
      const agent = navigator.userAgent.toUpperCase();
      let safari =
        agent.indexOf('SAFARI') >= 0 && agent.indexOf('CHROME') < 0
          ? true
          : false;

      if (/Mobi/i.test(window.navigator.userAgent) || safari) {
        location.href = url;
      } else {
        window.open(url, '_blank');
      }
    },
  },
};
</script>

<style lang="scss">
.footerTerm {
  .terms {
    height: auto;
  }
}
</style>
