function LoadView(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/subpage/parking/${view}.vue`
    );
}

const parkingRoutes = [
  /* 공영주차장 */
  // 주차장 이용안내
  {
    path: '/parkinginfo',
    name: 'parkingInfo',
    component: LoadView('parkingInfo'),
    meta: {
      category: ['공영주차장', '주차장 이용안내'],
    },
  },

  // 주차장 요금안내
  // {
  //   path: '/parkingfeeinfo',
  //   name: 'parkingFeeInfo',
  //   component: LoadView('parkingFeeInfo'),
  //   meta: {
  //     category: ['공영주차장', '주차장 요금안내'],
  //   },
  // },

  // 실시간 주차현황
  {
    path: '/parkingrealtime',
    name: 'parkingRealtime',
    component: LoadView('parkingRealtime'),
    meta: {
      category: ['공영주차장', '실시간 주차현황'],
    },
  },

  // 주차장 주소검색
  {
    path: '/parkingsearch',
    name: 'parkingSearch',
    component: LoadView('parkingSearch'),
    meta: {
      category: ['공영주차장', '주차장 주소검색'],
    },
  },
];

export default parkingRoutes;
